import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Cropper from 'cropperjs';

@Component({
  selector: 'app-photo-edit',
  templateUrl: './photo-edit.component.html',
  styleUrl: './photo-edit.component.scss'
})
export class PhotoEditComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('image', { static: true }) image: ElementRef<HTMLImageElement>;

  private cropper: Cropper;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { file: File, ratio: number },
    public dialogRef: MatDialogRef<PhotoEditComponent>
  ) {
    if (!this.data.ratio) {
      this.data.ratio = 1;
    }
  }

  ngOnInit() {
    this.dialogRef.backdropClick().subscribe(() => this.dialogRef.close());
  }

  ngAfterViewInit() {
    this.image.nativeElement.src = URL.createObjectURL(this.data.file);
    this.data.file = null;
    this.onResize();
  }

  ngOnDestroy() {
    URL.revokeObjectURL(this.image.nativeElement.src);
    this.image = null;
    this.cropper.destroy();
    this.cropper = null;
  }

  rotate(left: boolean) {
    const canvas = document.createElement('canvas');
    const image = this.image.nativeElement;
    canvas.width = image.height;
    canvas.height = image.width;
    const context = canvas.getContext('2d');
    if (left) {
      context.translate(0, image.width);
      context.rotate(-Math.PI / 2);
    } else {
      context.translate(image.height, 0);
      context.rotate(Math.PI / 2);
    }
    context.drawImage(image, 0, 0, image.width, image.height);
    image.src = canvas.toDataURL('image/jpeg');
    this.onResize();
  }

  async onSubmit() {
    const cropperData = this.cropper.getData();
    const height = Math.min(cropperData.height, 1080);
    const width = Math.min(cropperData.width, 1080 * this.data.ratio);
    let canvas = this.cropper.getCroppedCanvas({ height: height, width: width });
    this.dialogRef.close(canvas);
  }

  @HostListener('window:resize')
  onResize() {
    if (this.cropper) {
      this.cropper.destroy();
    }
    this.cropper = new Cropper(
      this.image.nativeElement,
      {
        aspectRatio: this.data.ratio,
        autoCropArea: 1,
        background: false,
        center: false,
        guides: false,
        minContainerHeight: 0,
        minContainerWidth: 0,
        movable: false,
        responsive: false,
        restore: false,
        viewMode: 2,
        zoomable: false
      }
    );
  }

}
