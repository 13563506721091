<h1 mat-dialog-title>{{ 'ACTION.SHARED.CROP_IMAGE' | translate }}</h1>
<form>
  <div id="image-container"
       [class.rounded-image]="data.ratio == 1">
    <img #image
         id="image" />
  </div>
  <div class="rotate-group">
    <button aria-label="rotate left"
            class="rotate-button"
            mat-icon-button
            type="button"
            (click)="rotate(true)">
      <mat-icon>rotate_left</mat-icon>
    </button>
    <button aria-label="rotate rigth"
            class="rotate-button"
            mat-icon-button
            type="button"
            (click)="rotate(false)">
      <mat-icon>rotate_right</mat-icon>
    </button>
  </div>
  <mat-dialog-actions align="end">
    <button aria-label="cancel"
            mat-raised-button
            color="accent"
            type="button"
            (click)="dialogRef.close()">
      {{ 'ACTION.SHARED.CANCEL' | translate }}
    </button>
    <button aria-label=save
            mat-raised-button
            color="primary"
            type="button"
            (singleClick)="onSubmit()">
      {{ 'ACTION.SHARED.SAVE_CHANGES' | translate }}
    </button>
  </mat-dialog-actions>
</form>